import React from 'react'
import styled from 'styled-components'

import routes from '../../constants/routes'

export interface NavigationProps {
  activeIndex: number
  onTabSelected: (route: string) => void
}

const items = [
  { label: 'Auctions', link: routes.auction },
  { label: 'Creations', link: routes.creation },
  { label: 'Users', link: routes.user },
]

const MainStyle = styled.div`
  padding: 0 60px;

  > div {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    color: grey;
    font-size: 14px;

    > div {
      padding: 5px 0;
      cursor: pointer;
    }

    > div + div {
      margin-left: 1em;
    }

    .selected {
      border-bottom: 2px solid blue;
      color: black;
    }
  }
`

const Navigation = ({
  activeIndex,
  onTabSelected,
}: NavigationProps): JSX.Element => {
  return (
    <MainStyle>
      <hr />
      <div>
        {items.map((x, index) => {
          const cls = index === activeIndex ? 'selected' : ''

          return (
            <div
              key={index}
              onClick={() => onTabSelected(items[index].link)}
              className={cls}>
              {x.label}
            </div>
          )
        })}
      </div>
      <hr />
    </MainStyle>
  )
}

export default Navigation
