/* eslint-disable compat/compat */
import React from 'react'
import styled from 'styled-components'

import IconLabel, { IconLabelStyled } from '../atoms/IconLabel'
import { Home, Atomium, Flow } from '../../svg'
import { useConfig } from '../../lib/context/config'
// type LinkType = { href: string; text: string }

// export interface FooterProps {
//   address: LinkType
//   keyId: string
// }

const MainStyle = styled.footer`
  display: flex;
  height: 50px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: blue;
  color: lightgray;
  font-family: 'Fira Mono', monospace;

  ${IconLabelStyled} {
    margin: 30px;
  }

  a {
    color: lightgray;
  }
`

const Footer: React.FC = () => {
  const config = useConfig()

  return (
    <MainStyle>
      <IconLabel icon={<Home />}>
        <a href={config.shopify.shop}>{config.shopify.store}</a>
      </IconLabel>
      <IconLabel icon={<Atomium />}>
        <a href={config.api.endpoint}>
          {new URL(config.api.endpoint).hostname}
        </a>
      </IconLabel>

      <IconLabel icon={<Flow />}>
        <a href={config.flow.accessNode}>
          {new URL(config.flow.accessNode).hostname}
        </a>
      </IconLabel>
    </MainStyle>
  )
}

export default Footer
