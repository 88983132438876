import React from 'react'
import styled from 'styled-components'

import Logo from '../atoms/Logo'
import Navigation from '../atoms/Navigation'

export interface HeaderProps {
  children: JSX.Element
  activeTabIndex?: number
}

const MainStyle = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 60px;
`

const tabSelectedHandler = () => {
  // TODO: change route here
}

const Header = ({ children, activeTabIndex = 0 }: HeaderProps): JSX.Element => {
  return (
    <>
      <MainStyle>
        <div>
          <Logo />
        </div>
        <div>{children}</div>
      </MainStyle>
      <Navigation
        activeIndex={activeTabIndex}
        onTabSelected={tabSelectedHandler}
      />
    </>
  )
}

export default Header
