import React from 'react'
import styled from 'styled-components'

import Avatar, { AvatarProps } from '../atoms/Avatar'
import Footer from '../molecules/Footer'
import Header from './Header'

export interface BaseTemplateProps {
  main: JSX.Element
  header?: AvatarProps
  activeTabIndex?: number
}

interface BaseTemplateStyleProps {
  height: string
}

const MainStyle = styled.div`
  position: relative;
`

const BaseTemplateStyle = styled.div<BaseTemplateStyleProps>`
  width: 100vw;
  max-width: 1400px;
  margin: auto;
  font-family: 'Works Sans', sans-serif;

  main {
    display: flex;
    min-height: calc(100vh - 50px - ${p => p.height});
    max-height: 753px;
    justify-content: center;
  }
`

const FooterStyle = styled.div`
  position: absolute;
  bottom: -54px;
  width: 100vw;
`

const BaseTemplate = ({
  main,
  header,
  activeTabIndex,
}: BaseTemplateProps): JSX.Element => {
  return (
    <MainStyle>
      <BaseTemplateStyle height={header ? '108px' : '0px'}>
        {header && (
          <Header activeTabIndex={activeTabIndex || 0}>
            <Avatar initials={header.initials} label={header.label} />
          </Header>
        )}
        <main>{main}</main>
      </BaseTemplateStyle>

      <FooterStyle>
        <Footer />
      </FooterStyle>
    </MainStyle>
  )
}

export default BaseTemplate
