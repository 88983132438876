import * as React from 'react'

function SvgShevronLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={21}
      height={20}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5 16a.997.997 0 01-.707-.293l-5-5a.999.999 0 010-1.414l5-5a.999.999 0 111.414 1.414L8.914 10l4.293 4.293A.999.999 0 0112.5 16z'
        fill='#5C5F62'
      />
    </svg>
  )
}

export default SvgShevronLeft
