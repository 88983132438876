import React from 'react'
import styled from 'styled-components'

const MainStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Fira Mono', monospace;
  font-size: 14px;
  line-height: 20px;

  > span + span {
    margin-left: 8px;
  }
`

export interface IconLabelProps {
  icon: JSX.Element
  children: JSX.Element
}

const IconLabel = ({ icon, children }: IconLabelProps): JSX.Element => {
  return (
    <MainStyle>
      <span>{icon}</span>
      <span>{children}</span>
    </MainStyle>
  )
}
export { MainStyle as IconLabelStyled }
export default IconLabel
