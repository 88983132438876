import React, { useState } from 'react'
import styled from 'styled-components'

import { Logout } from '../../svg'

export interface AvatarProps {
  label: string
  initials: string
  onLogout?: () => void
}

const MainStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: 14px;

  > span > div {
    display: flex;
    width: 2em;
    height: 2em;
    align-items: center;
    justify-content: center;
    background-color: #ffc96b;
    border-radius: 50%;
  }

  > span + span {
    margin-left: 1em;
  }

  > span:last-child {
    font-family: 'Fira Mono', monospace;
  }
`

const MenuStyle = styled.div`
  position: absolute;
  height: 3em;
  border: 0 solid grey;
  margin-top: 1em;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 #0000001a;
  color: red;
  cursor: pointer;
  font-size: 14px;
`

export const AvatarComponent = ({
  label,
  initials,
  onLogout = () => {
    /** do nothing */
  },
}: AvatarProps): JSX.Element => {
  const [showMenu, setShowMenu] = useState(false)

  const handleClick = () => {
    setShowMenu(!showMenu)
  }

  const logoutClickHandler = () => {
    setShowMenu(false)
    onLogout()
  }

  return (
    <>
      <MainStyle onClick={handleClick}>
        <span>
          <div>{initials}</div>
        </span>
        <span>{label}</span>
      </MainStyle>
      {showMenu && (
        <MenuStyle onClick={logoutClickHandler}>
          <Logout />
        </MenuStyle>
      )}
    </>
  )
}

export default AvatarComponent
