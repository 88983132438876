import * as React from 'react'

function SvgLogout(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={148}
      height={40}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M45.258 25h5.988v-1.107h-4.758v-8.757h-1.23V25zm10.493.13c2.099 0 3.397-1.45 3.397-3.808v-.013c0-2.366-1.298-3.808-3.397-3.808-2.099 0-3.398 1.442-3.398 3.808v.013c0 2.359 1.3 3.808 3.398 3.808zm0-1.053c-1.395 0-2.18-1.018-2.18-2.755v-.013c0-1.744.785-2.755 2.18-2.755s2.18 1.011 2.18 2.755v.013c0 1.737-.785 2.755-2.18 2.755zm8.278 3.52c2.01 0 3.282-1.052 3.282-2.7v-7.266h-1.19v1.217h-.082c-.451-.834-1.258-1.347-2.297-1.347-1.927 0-3.117 1.497-3.117 3.561v.014c0 2.065 1.183 3.541 3.09 3.541 1.012 0 1.846-.458 2.31-1.271h.11v1.49c0 1.087-.786 1.709-2.106 1.709-1.06 0-1.715-.397-1.845-.957l-.007-.007h-1.23l-.014.007c.184 1.21 1.319 2.01 3.096 2.01zm-.054-4.033c-1.368 0-2.133-1.025-2.133-2.488v-.014c0-1.462.765-2.508 2.133-2.508 1.36 0 2.187 1.046 2.187 2.509v.013c0 1.463-.82 2.488-2.187 2.488zm13.78 1.668c2.817 0 4.526-2.016 4.526-5.16v-.014c0-3.159-1.722-5.155-4.525-5.155-2.79 0-4.525 1.99-4.525 5.155v.013c0 3.152 1.695 5.161 4.525 5.161zm0-1.134c-2.05 0-3.267-1.586-3.267-4.027v-.013c0-2.461 1.258-4.02 3.268-4.02 2.016 0 3.267 1.559 3.267 4.02v.013c0 2.44-1.244 4.027-3.267 4.027zm8.976 1.032c1.08 0 1.826-.444 2.188-1.23h.11V25h1.189v-7.37h-1.19v4.362c0 1.292-.69 2.085-1.989 2.085-1.176 0-1.654-.636-1.654-1.962v-4.484h-1.19v4.771c0 1.743.862 2.728 2.536 2.728zm8.327-.075c.232 0 .458-.028.69-.069v-1.011c-.219.02-.335.027-.547.027-.765 0-1.066-.349-1.066-1.217v-4.17h1.613v-.984h-1.613v-1.907h-1.23v1.907h-1.163v.984h1.162v4.47c0 1.41.636 1.97 2.154 1.97z'
        fill='#D72C0D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 29a8 8 0 100-16 8 8 0 000 16zm-.707-10.293a1 1 0 011.414-1.414l3 3a1 1 0 010 1.414l-3 3a1 1 0 01-1.414-1.414L18.586 22H15a1 1 0 110-2h3.586l-1.293-1.293z'
        fill='#D72C0D'
      />
    </svg>
  )
}

export default SvgLogout
