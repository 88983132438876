import * as React from 'react'

function SvgShevronRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={21}
      height={20}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.5 16a.999.999 0 01-.707-1.707L12.086 10 7.793 5.707a.999.999 0 111.414-1.414l5 5a.999.999 0 010 1.414l-5 5A.997.997 0 018.5 16z'
        fill='#5C5F62'
      />
    </svg>
  )
}

export default SvgShevronRight
