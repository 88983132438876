import * as React from 'react'

function SvgAtomium(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M6.086 9.882a5 5 0 113.91-5.063l4.378.73A3 3 0 0120 7a3 3 0 01-3.919 2.857l-2.866 3.763a4 4 0 11-5.77-.697l-1.36-3.041zm1.826-.817l1.342 3.005a4.022 4.022 0 012.407.29l2.83-3.716a2.983 2.983 0 01-.446-1.123L9.67 6.792a5.015 5.015 0 01-1.757 2.273z'
        fill='#d3d3d3'
      />
    </svg>
  )
}

export default SvgAtomium
