import * as React from 'react'

function SvgFlow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      fill='currentColor'
      {...props}>
      <path d='M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm-1.258 8.436h-1.06a3.882 3.882 0 103.882 3.882V13.26h2.824v-2.824h-2.824v2.822h-2.822v1.06a1.06 1.06 0 11-1.06-1.06h1.06v-2.822zM17.8 6.2h-3.176a3.886 3.886 0 00-3.882 3.882v.354h2.822v-.354a1.06 1.06 0 011.06-1.058H17.8V6.2z' />
    </svg>
  )
}

export default SvgFlow
