import React from 'react'
import styled from 'styled-components'

import { Logo as SvgLogo } from '../../svg'

const MainStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  > span + span {
    margin-left: 1em;
  }
`

const Logo = (): JSX.Element => {
  return (
    <MainStyle>
      <span>
        <SvgLogo />
      </span>
      <span>NFT Admin</span>
    </MainStyle>
  )
}

export default Logo
